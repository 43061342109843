<template>
  <div id="webMessageListPage">
    <!-- Header -->
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <div class="text-2xl font-semibold text-gray-900">Forms & Popups</div>
        <p class="mt-1 text-sm text-gray-700">Capture new store visitors with a signup form.</p>
      </div>
      <div class="mt-0 sm:mt-0 sm:ml-16 sm:flex-none">
        <!-- <el-button size="small" type="primary"><i class="fa-regular fa-plus"></i> &nbsp; Create</el-button> -->
        <el-button type="primary" @click="showCreateDialog"> <i class="fa-regular fa-plus"></i> &nbsp; Create</el-button>
      </div>
    </div>

    <!-- Loader -->
    <div class="loadingDiv" v-if="fetchingData && webMessageList" v-loading="true" style="min-height: 300px"></div>

    <!-- WebMessage List -->
    <template v-if="!fetchingData && webMessageList && webMessageList.length > 0">
      <div class="overflow-hidden mt-2 w-full border border-b-none sm:rounded-lg mb-4">
        <!-- Header -->
        <el-row class="py-2.5 pl-0 text-gray-500 bg-gray-50 text-sm font-semibold text-gray-900 text-left">
          <el-col :span="8" class="pl-3 pt-2.5"> Name </el-col>
          <el-col :span="5" class="pl-3 pt-2.5"> Status </el-col>
          <el-col :span="6" class="pl-3 pt-2.5"> Type </el-col>
          <el-col :span="5" class="pl-3 pt-2.5"> Last Updated </el-col>
        </el-row>

        <!-- List -->
        <el-row class="py-2.5 pl-0 border-t bg-white text-left" v-for="(webMessage, index) in webMessageList" :key="index">
          <el-col :span="8" class="pl-3 pt-2.5 font-semibold cursor-pointer" style="font-size: 14px">
            <router-link class="text-primary-600 hover:text-primary-600" :to="getWebMessageDetailsPageLink(webMessage)">
              {{ webMessage.name }}
            </router-link>
          </el-col>
          <el-col :span="5" class="pl-3 pt-2.5 text-sm">
            <span v-if="webMessage.status == 'draft'" class="inline-flex items-center rounded-full bg-gray-600 px-2.5 py-0.5 text-xs font-medium text-white">{{ toPascalCase(webMessage.status) }}</span>
            <span v-else-if="webMessage.status == 'running'" class="inline-flex items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-white">{{ toPascalCase(webMessage.status) }}</span>
            <span v-else-if="webMessage.status == 'paused'" class="inline-flex items-center rounded-full bg-red-500 px-2.5 py-0.5 text-xs font-medium text-white">{{ toPascalCase(webMessage.status) }}</span>
          </el-col>
          <el-col :span="6" class="pl-3 pt-2.5 text-sm"> {{ webMessageTypes[webMessage.messageType].displayText }} </el-col>

          <el-col :span="5" class="pl-3 pt-2.5 text-xs text-gray-600">
            {{ webMessage.updated_at }} <br />
            By {{ webMessage.updator_name }}
          </el-col>
        </el-row>
      </div>
    </template>

    <!-- No Data -->
    <div class="bg-white border rounded-lg overflow-hidden p-3 text-center" v-if="!fetchingData && webMessageList && webMessageList.length == 0">
      <div class="pt-40 text-2xl">No Forms Found</div>
      <div class="pt-4">
        <el-button @click="showCreateDialog" :loading="creatingWebMessage" type="primary"> Create Form </el-button>
      </div>

      <div class="pt-10 text-sm text-gray-500 pb-20">
        98% of visitor leave without buying.
        <br />
        Turn more store visitors into buyers by capturing their email/mobile number and selling to them later.
        <br />
        Turn back on existing signup Form or launch a new one.
      </div>
    </div>

    <!-- Dialog - Create Web Message -->
    <el-dialog append-to-body custom-class="createWebMessagePopUp" :visible.sync="createDialogVisible" width="600px" center title="Create Website Display" :show-close="false" :destroy-on-close="true">
      <el-form ref="createWebMessageForm" class="form-vertical-label" @submit.native.prevent>
        <!-- // template name -->
        <el-form-item required label="Name">
          <el-input v-model="createWebMessageForm.name" @keyup.enter.native="callCreateMessageApi" placeholder="Enter template name to continue"> </el-input>
        </el-form-item>

        <!-- Web Message Type -->
        <div class="webMessageTypeContainer">
          <div class="text-gray-600 mb-2 mt-4 text-sm font-semibold">Choose a display type</div>
          <!-- Popup -->
          <div v-for="(block, index) in webMessageTypeInfo" :key="index" @click="createWebMessageForm.type = block.type">
            <el-row class="mb-3 px-3 py-3 rounded-sm webMessageTypeBox cursor-pointer" :class="{ isSelected: createWebMessageForm.type == block.type }">
              <el-col :span="4">
                <img class="w-full" :src="block.img" />
              </el-col>
              <el-col :span="20" class="text-left pl-4">
                <div class="title text-base font-bold text-gray-700">{{ block.title }}</div>
                <div class="text-sm">{{ block.description }}</div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-form>

      <template #footer>
        <div class="text-right w-full">
          <el-button type="primary" size="medium" @click="callCreateMessageApi()" :loading="creatingWebMessage">Create </el-button>
          <el-button type="danger" size="medium" @click="createDialogVisible = false">Cancel</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<style lang="scss" src="./webMessageList.scss"></style>

<script>
import webMessageListComponent from './webMessageListComponent';
export default webMessageListComponent;
</script>
